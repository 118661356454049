import { axiosInstance } from '../Axios';
import { setSelectedArrival, setSelectedDepart } from './UiReducer';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const HomeReducer = createSlice({
	name: 'home',
	initialState: {
		success: false,
		error: null,
		loading: false,
		city: null,
		banner: null,
		tourdest: null,
		tourpackage: null,
		footerMenu: null,
	},
	reducers: {
		cityRequest(state, action) {
			// state.loading = true;
		},
		citySuccess(state, action) {
			// state.loading = false;
			state.city = action.payload;
			state.error = null;
		},
		cityFail(state, action) {
			// state.loading = false;
			state.error = action.payload;
		},
		bannerRequest(state, action) {
			state.loading = true;
		},
		bannerSuccess(state, action) {
			state.loading = false;
			state.banner = action.payload;
			state.error = null;
		},
		bannerFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourdestRequest(state, action) {
			state.loading = true;
		},
		tourdestSuccess(state, action) {
			state.loading = false;
			state.tourdest = action.payload;
			state.error = null;
		},
		tourdestFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		tourpackageRequest(state, action) {
			state.loading = true;
		},
		tourpackageSuccess(state, action) {
			state.loading = false;
			state.tourpackage = action.payload;
			state.error = null;
		},
		tourpackageFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		footerMenuRequest(state, action) {
			state.loading = true;
		},
		footerMenuSuccess(state, action) {
			state.loading = false;
			state.footerMenu = action.payload;
			state.error = null;
		},
		footerMenuFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = HomeReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};
export const {
	cityRequest,
	citySuccess,
	cityFail,
	bannerRequest,
	bannerSuccess,
	bannerFail,
	tourdestRequest,
	tourdestSuccess,
	tourdestFail,
	tourpackageRequest,
	tourpackageSuccess,
	tourpackageFail,
	footerMenuRequest,
	footerMenuSuccess,
	footerMenuFail,
} = actions;

export const cityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityRequest());
		const { data } = await axiosInstance.post(
			'/home/flightairport',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(citySuccess(data));
		if (bodyData.limits === 8) {
			dispatch(setSelectedDepart(data?.result[1]));
			dispatch(setSelectedArrival(data?.result[3]));
		}
	} catch (error) {
		dispatch(
			cityFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showBannerDispatch = () => async (dispatch) => {
	try {
		dispatch(bannerRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/banner',
			config
		);
		console.log('data', data);
		dispatch(bannerSuccess(data));
	} catch (error) {
		dispatch(
			bannerFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showtourdestDispatch = () => async (dispatch) => {
	try {
		dispatch(tourdestRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/tourdest',
			config
		);
		console.log('data', data);
		dispatch(tourdestSuccess(data));
	} catch (error) {
		dispatch(
			tourdestFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const showtourpackageDispatch = () => async (dispatch) => {
	try {
		dispatch(tourpackageRequest());
		const { data } = await axios.get(
			'https://api.marinersforex.com/api/home/tourpackage',
			config
		);
		console.log('data', data);
		dispatch(tourpackageSuccess(data));
	} catch (error) {
		dispatch(
			tourpackageFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const showFootermenuDispatch = () => async (dispatch) => {
	try {
		dispatch(footerMenuRequest());
		const { data } = await axiosInstance.get('/home/footer_submenu', config);
		console.log('data', data);
		dispatch(footerMenuSuccess(data));
	} catch (error) {
		dispatch(
			footerMenuFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default HomeReducer;
