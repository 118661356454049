import React from 'react';
import {
	Grid,
	Container,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import travelG from '../../assets/images/travelG.svg';
import personal from '../../assets/images/personal.svg';
import loca from '../../assets/images/location.svg';
const ExploreWorld = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div style={{ marginTop: 100 }}>
			<Container>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={6}>
						<img
							src={
								'https://imgak.mmtcdn.com/pwa_v3/pwa_header_assets/loginPersuassionOcean.webp'
							}
							style={{ height: '100%', borderRadius: 5 }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={6}
						style={{ textAlign: matches ? 'center' : '' }}>
						<span
							title='Explore the world'
							style={{
								color: 'rgba(77, 165, 40, 1)',
								fontSize: 18,
								fontWeight: '500',
							}}>
							Explore the world
						</span>
						<h1
							style={{
								fontSize: matches ? 38 : 50,
								fontFamily: 'Plus Jakarta Sans',
								fontWeight: 700,
							}}>
							Great opportunity for{' '}
							<span style={{ color: 'rgba(182, 194, 212, 1)' }}>
								adventure{' '}
							</span>
							& travels
						</h1>
						<p
							style={{
								fontSize: 16,
								color: 'rgba(79, 84, 90, 1)',
								marginTop: matches ? 20 : '',
							}}>
							Welcome to our marinersforex website!
						</p>
						<Grid container spacing={4} style={{ marginTop: 10 }}>
							<Grid
								item
								xs={12}
								lg={6}
								style={{ textAlign: matches ? '-webkit-center' : '' }}>
								<img src={travelG} style={{ height: 50 }} />
								<h1 style={{ fontWeight: 700, marginTop: 10 }}>
									Trusted travel guide
								</h1>
								<p
									style={{
										color: 'rgba(79, 84, 90, 1)',
										marginTop: 10,
										padding: matches ? 10 : '',
									}}>
									Welcome to our marinersforex wesite!
								</p>
								<Button
									style={{
										backgroundColor: 'rgba(77, 165, 40, 1)',
										color: 'white',
										padding: '15px 30px',
										marginTop: 25,
									}}>
									More about us
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
								style={{ textAlign: matches ? '-webkit-center' : '' }}>
								<img src={personal} style={{ height: 45 }} />
								<h1 style={{ fontWeight: 700, marginTop: 10 }}>
									Pesonalized Trips
								</h1>
								<p
									style={{
										color: 'rgba(79, 84, 90, 1)',
										marginTop: 10,
										padding: matches ? 10 : '',
									}}>
									Welcome to our marinersforex website!
								</p>
							</Grid>
						</Grid>
						<div
							style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
							<img src={loca} />
							<p
								style={{
									color: 'rgba(77, 165, 40, 1)',
									fontSize: 14,
									fontWeight: '500',
								}}>
								Checkout Beautiful Places Arround the World.
							</p>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ExploreWorld;
